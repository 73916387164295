import { Component, Input } from '@angular/core';
import { Lead } from '../../../model/lead.model';
import { ILang } from '../../../model/lang.interface';
import { IProcess } from '../../../model/process.interface';
import { IAssignee } from '../../../model/assignee.interface';
import { IStatus } from '../../../model/status.interface';
import { ISubstatus } from '../../../model/substatus.interface';
import { User } from '../../../model/user.model';

@Component({
	selector: 'lead-edit',
	templateUrl: 'lead.edit.component.html',
	styleUrls: ["../../../app.component.scss", "./lead.edit.component.scss"],
})
export class LeadEditComponent {
	@Input() lead: Lead;
	@Input() currentLang: ILang;
	@Input() processes: IProcess[];
	@Input() assignees: IAssignee[];
	@Input() user: User | null = null;

	public phoneError = "";
	public emailError = "";

	
	public getProcesses(): IProcess[] {
		return this.processes;
	}

	public getStatuses(): IStatus[] {
		let statuses: IStatus[] = [];
		let currentProcess: IProcess | null = this.processes.find (p => p.id === this.lead.leadProcessId) || null;

		if (currentProcess) {
			statuses = currentProcess.statuses;
		}

		return statuses;
	}

	public getSubstatuses(): ISubstatus[] {
		let substatuses: ISubstatus[] = [];
		let currentProcess: IProcess | null = this.processes.find (p => p.id === this.lead.leadProcessId) || null;

		if (currentProcess) {
			let currentStatus: IStatus | null = currentProcess.statuses.find(s => s.id === this.lead.leadStatusId) || null;

			if (currentStatus) {
				substatuses = currentStatus.subStatuses.filter(x=>x.isSelected == true);
			}
		}

		return substatuses;
	}

	public validatePhoneOnlyNumbers(): void {
		if (this.lead.phone) {
			this.lead.phone = this.lead.phone.replace(/[^0-9]+/g, "");
		}
	}

	public ValidatePhone() {
		if (!this.lead.phone.startsWith('05')) {
			this.lead.phoneError = this.currentLang.words["enter-phone-validation-start"];
		} else if (this.lead.phone.length !== 10) {
			this.lead.phoneError = this.currentLang.words["enter-phone-validation-10-digits"]
		} else {
			this.lead.phoneError = "";
		}
	}

	public validateEmail(): void {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!re.test(String(this.lead.email).toLowerCase())) {
			this.lead.emailError = this.currentLang.words["correct-email"];
		} else {
			this.lead.emailError = "";
		}
	}

	public onStatusChanged(): void {
		this.lead.leadAdditionalStatusId = null;
	}

	public onAssigneeChanged(): void {
		let assignee: IAssignee | null = this.assignees.find(a => a.id === this.lead.assigneeId) || null;
		this.lead.assigneeName = (assignee) ? assignee.name : "";
	}

	public isEmpty(v: any): boolean {
		return v === null || v === undefined;
	}
}
