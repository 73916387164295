import { Component, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { LeadCalendarComponent } from './lead.calendar.component';

@Component({
    selector: 'lead-calendar-block',
    templateUrl: 'lead.calendar.block.component.html',
    styleUrls: ["./lead.calendar.block.component.scss", "../../../calendar.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LeadCalendarBlockComponent extends LeadCalendarComponent {
    constructor(
        protected appService: AppService
    ) {
        super(appService);
    }
}
