import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'safehtml' })
export class SafeHtmlPipe implements PipeTransform {
   constructor(private domSanitizer: DomSanitizer) {
   }

   public transform (url: string): SafeHtml {
      return this.domSanitizer.bypassSecurityTrustHtml (url);
   }
}
