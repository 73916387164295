import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../services/app.service';
import { LeadCalendarComponent } from './lead.calendar.component';

@Component({
    selector: 'lead-calendar-win',
    templateUrl: 'lead.calendar.win.component.html',
    styleUrls: ["../../../app.component.scss", "../../../calendar.scss", "lead.calendar.win.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class LeadCalendarWinComponent extends LeadCalendarComponent {
    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(protected appService: AppService) {
        super(appService);
    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }
}
