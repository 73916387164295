import { Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ILang } from '../../../model/lang.interface';
import { Lead } from '../../../model/lead.model';
import { LeadDatetime } from '../../../model/leaddatetime.interface';
import { IMyDpOptions } from 'mydatepicker';
import { IMyDpShortDate } from '../../../model/dp.shortdate.interface';
import { AppService } from '../../../services/app.service';

export abstract class LeadCalendarComponent implements OnInit, OnChanges {
    @Input() currentLang: ILang;
    @Input() lead: Lead;
    @Output() followupChange: EventEmitter<LeadDatetime> = new EventEmitter<LeadDatetime>();
    public hours: number[] = [];
    public minutes: number[] = [];
    public options: IMyDpOptions = {
        inline: true,
        showTodayBtn: false,
        selectorWidth: "100%",
    };
    public currentDate: IMyDpShortDate = {};
    public currentHour: number = 0;
    public currentMinute: number = 0;
    public ready: boolean = false;

    constructor(protected appService: AppService) {
    }

    ngOnInit(): void {
        this.hours = this.appService.range(0, 23);
        this.minutes = [0, 15, 30, 45];

        this.options.dayLabels = this.currentLang.slug === 'he'
            ? { su: 'א', mo: 'ב', tu: 'ג', we: 'ד', th: 'ה', fr: 'ו', sa: 'ש' }
            : { su: 'Sun', mo: 'Mon', tu: 'Tue', we: 'Wed', th: 'Thu', fr: 'Fri', sa: 'Sat' };

        this.options.monthLabels = this.currentLang.slug === 'he'
            ? { 1: 'ינואר', 2: 'פברואר', 3: 'מרץ', 4: 'אפריל', 5: 'מאי', 6: 'יוני', 7: 'יולי', 8: 'אוגוסט', 9: 'ספטמבר', 10: 'אוקטובר', 11: 'נובמבר', 12: 'דצמבר' }
            : { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };

    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.lead) {
            this.currentDate = this.lead.calendarFollowupDate;
            this.currentHour = this.lead.followupHour;
            this.currentMinute = this.minutes.includes(this.lead.followupMinute) ? this.lead.followupMinute : 0;
            this.ready = true;
        } else {
            this.ready = false;
        }
    }

    // now + N days
    public calendarForward(nDays: number): void {
        let now: Date = new Date();
        let timestamp: number = now.getTime() + 1000 * 60 * 60 * 24 * nDays;
        let newDate: Date = new Date(timestamp);
        this.currentDate = { date: { year: newDate.getFullYear(), month: newDate.getMonth() + 1, day: newDate.getDate() } };
        this.currentHour = newDate.getHours();
        this.currentMinute = 0;
    }

    public apply(): void {
        this.followupChange.emit({
            year: this.currentDate.date.year,
            month: this.currentDate.date.month,
            day: this.currentDate.date.day,
            hour: this.currentHour,
            minute: this.currentMinute
        });
    }
}
