import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject, Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { NotificationService } from "./notification.service";
import { User } from "../model/user.model";
import { LeadClientWhatsappReceivedMessage } from "../model/leadClients/lead.client.whatsapp.receivedmessage";

@Injectable()
export class WhatsappService {
    private isConnected = false;
    private signalRConnectionSubject = new BehaviorSubject<signalR.HubConnection>(null);
    public signalRConnection$ = this.signalRConnectionSubject.asObservable();
    public signalRConnection: signalR.HubConnection;
    public signalRConnectionSubscription: Subscription;
    private receivedMessageSubject = new BehaviorSubject<LeadClientWhatsappReceivedMessage>(null);
    public receivedMessage$ = this.receivedMessageSubject.asObservable();
    public receivedMessage: LeadClientWhatsappReceivedMessage;
    public receivedMessageSubscription: Subscription;
    private signalRConnectionCloseRequested = false;

    constructor(
        public readonly notificationService: NotificationService,
        private platform: Platform
    ) { }

    setSignalRConnection(signalRConnection: signalR.HubConnection) {
        this.signalRConnectionSubject.next(signalRConnection);
    }

    setReceivedMessage(receivedMessage: LeadClientWhatsappReceivedMessage) {
        this.receivedMessageSubject.next(receivedMessage);
    }

    public async startConnection(userEmail: string) {
        try {
            console.log('startConnection', userEmail);
            this.signalRConnectionCloseRequested = false;
            this.signalRConnection.start().then(async () => {
                if (this.signalRConnection) {
                    await this.signalRConnection.invoke('ConnectUser', userEmail);
                    this.isConnected = true;
                }
            }, err => {
                console.error('start signalRConnection rejected', err)
            }).catch(err => {
                console.error('start signalRConnection error', err)
            });
        } catch (err) {
            console.error('startConnection error', err);
            setTimeout(() => {
                this.startConnection(userEmail);
            }, 5000);
        }
    };

    public async subscribe(user: User): Promise<void> {
        await this.platform.ready();

        this.signalRConnectionSubscription = this.signalRConnection$.subscribe(async (con: signalR.HubConnection) => {
            if (con !== null && !this.signalRConnection) {
                this.signalRConnection = con;
                await this.startConnection(user.email);
            }
        });

        let connection = new signalR.HubConnectionBuilder()
            .withUrl(`${environment.baseFixUrl}chatHub`, { transport: signalR.HttpTransportType.WebSockets, skipNegotiation: true })
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();
        connection.onclose(async (error?: Error) => {
            if (!this.signalRConnectionCloseRequested) {
                await this.startConnection(user.email);
            }
            if (error) {
                console.error("error in connection.onclose", error);
            }
        });
        this.setSignalRConnection(connection);
    }

    public unsubscribe(user: User): void {
        if (this.signalRConnection) {
            if (this.signalRConnection.state == signalR.HubConnectionState.Connected) {
                this.signalRConnection.invoke('DisconnectUser', user.email)
                    .finally(() => {
                        if (this.signalRConnection) {
                            this.signalRConnectionCloseRequested = true;
                            if (this.isConnected) {
                                this.signalRConnection.stop().then(_ => {
                                    this.signalRConnection = null;
                                    this.setSignalRConnection(null);
                                    this.isConnected = false;
                                });
                            }
                        }
                    });
            } else {
                this.signalRConnection = null;
                this.setSignalRConnection(null);
            }

            if (this.signalRConnectionSubscription) {
                this.signalRConnectionSubscription.unsubscribe();
            }
            this.setReceivedMessage(null);
            this.receivedMessage = null;
            if (this.receivedMessageSubscription) {
                this.receivedMessageSubscription.unsubscribe();
            }
        }
    }
}
