import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'eol'})
export class EolPipe implements PipeTransform 
{
    transform(value: string): string 
    {    
        if (value) 
        {
            let newValue = value.replace (/\n/g, "<br>").replace (/\r/g, "");    
            return `${newValue}`;
        }
        else 
        {
            return "";
        }
    }
}