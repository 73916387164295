export interface LeadPermission {
    id: number;
    clientId: number;
    tenantId: number;
    restriction: LeadPermissionType;
  }
  
  export enum LeadPermissionType {
    Price = 0,
    Referal = 1,
    Source = 2,
    Url = 3,
    Commitment = 4,
    CommitmentPrice = 5,
    Phones = 6,
    PhonesWithEdit = 7,
    Events = 8,
    EventsWithEdit = 9,
    Sites = 10,
    SitesWithEdit = 11,
    LandPages = 12,
    LandPagesWithEdit = 13,
    Custom = 14,
    CustomWithEdit = 15,
    Campaigns = 16,
    CampaignsWithEdit = 17,
    UrlCrm = 18,
    ReferalCrm = 19,
    SourceCrm = 20,
    PriceCrm = 21,
    LeadCountReport = 22,
    CommitmentWithEdit = 23,
    LeadsByArrivalSourceReport = 24,
    SummaryByStatusReport = 25,
    NumberOfLeadsByStatusReport = 26,
    TotalLeadsByArrivalSourceReport = 27,
    ArrivalSourceAndStatusReport = 28,
    CurrentSalesByResponsibleReport = 29,
    LeadsScreenCharts = 30,
    CampaignNameInLeads = 31,
    LeadsScreenKeywords = 40,
    CampaignsWithPrice = 50,
    Tags = 51,
    TagsWithEdit = 52,
    Facebook = 53,
    FacebookWithEdit = 54,
    LeadStatuses = 55,
    LeadStatusesWithEdit = 56,
    RealTimeGoogleReport = 57,
    RealTimeFacebookReport = 58,
    ScheduledReports = 59,
    ChannelDealsReport = 60,
    LeadsSummaryBySourceReport = 61,
    LeadsSummaryByTagsReport = 62
  }
  