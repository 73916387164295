import { Component, Input, ViewChild, ElementRef, AfterViewInit, HostListener, Output, EventEmitter } from '@angular/core';
import { Lead } from '../../../model/lead.model';
import { ILang } from '../../../model/lang.interface';
import { IStatus } from '../../../model/status.interface';
import { ISubstatus } from '../../../model/substatus.interface';
import { IProcess } from '../../../model/process.interface';
import { User } from '../../../model/user.model';
import { LeadPermissionType } from 'src/app/model/leadpermission.interface';

@Component({
    selector: 'lead-view',
    templateUrl: 'lead.view.component.html',
    styleUrls: ["../../../app.component.scss", "./lead.view.component.scss"],
})
export class LeadViewComponent implements AfterViewInit {
    @Input() lead: Lead;
    @Input() currentLang: ILang;
    @Input() token: string;
    @Input() processes: IProcess[];
    @Input() user: User | null = null;
    @Output() calendarActivation: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() leadStatusChange: EventEmitter<number> = new EventEmitter<number>();
    // scrolling button for lead content
    @ViewChild("outerContent", { static: false }) private outerContent: ElementRef;
    @ViewChild("innerContent", { static: false }) private innerContent: ElementRef;
    public isOverflow: boolean = false;
    public ready: boolean = false;

    LeadPermissionType = LeadPermissionType;

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.adjust();
        }, 0);
    }

    private adjust(): void {
        let h1: number = (this.outerContent.nativeElement as HTMLElement).offsetHeight;
        let h2: number = (this.innerContent.nativeElement as HTMLElement).offsetHeight;
        this.isOverflow = h2 + 20 > h1;
    }

    public scrollDown(): void {
        let elem: HTMLElement = this.outerContent.nativeElement as HTMLElement;
        let to: number = elem.scrollHeight;
        let from: number = elem.scrollTop;
        let change: number = to - from;
        let currentTime = 0;
        let increment = 10;
        let duration = 500;
        let animateScroll = () => {
            currentTime += increment;
            let val: number = this.easeInOutQuad(currentTime, from, change, duration);
            elem.scrollTo(0, val);

            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    private easeInOutQuad(t: number, b: number, c: number, d: number): number {
        t /= d / 2;
        if (t < 1) {
            return c / 2 * t * t + b;
        }
        t--;
        return -c / 2 * (t * (t - 2) - 1) + b;
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event): void {
        this.adjust();
    }

    public getLeadSubstatusTitle(): string {
        let res: string = "";
        let status: IStatus | null = this.processes[0].statuses.find(s => s.id === this.lead.leadStatusId) || null;

        if (status) {
            let substatus: ISubstatus | null = status.subStatuses.find(ss => ss.id === this.lead.leadAdditionalStatusId) || null;

            if (substatus) {
                res = substatus.title;
            }
        }

        return res;
    }

    public openCalendar(): void {
        if ((this.user && (this.user.canEditAllLeads || this.user.id === this.lead.assigneeId)) || this.user == null) {
            this.calendarActivation.emit(true);
        }
    }

    public setLeadStatus(statusId: number): void {
        if ((this.user && (this.user.canEditAllLeads || this.user.id === this.lead.assigneeId)) || this.user == null) {
            this.leadStatusChange.emit(statusId);
        }
    }

    public urlify(text): string {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, '<a href="$1">$1</a>')
    }

    public hasRestriction = (type: LeadPermissionType): boolean => this.lead.restrictions.some((i: LeadPermissionType) => i === type);
}
