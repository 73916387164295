import { Component, ViewEncapsulation } from '@angular/core';
import { LangsRepository } from '../services/langs.repository';
import { AppService } from '../services/app.service';
import { ILang } from '../model/lang.interface';
import { User } from '../model/user.model';
import { AuthService } from '../services/auth.service';

@Component({
    selector: 'settings-page',
    templateUrl: 'settings.page.html',
    styleUrls: ['settings.page.scss', "../app.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class SettingsPage {
    public langsReady = false;
    public documentsSettingActive: boolean = false;
    public notificationsSettingsActive: boolean = false;

    constructor(
        private langsRepository: LangsRepository,
        private appService: AppService,
        private authService: AuthService,
    ) {
        this.init();
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
    }

    get currentLang(): ILang { return this.langsRepository.current; }
    get user(): User { return this.authService.user; }
    set menuClass(v: string) { this.appService.menuClass = v; }
}
