import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { ILang } from 'src/app/model/lang.interface';
import { User } from 'src/app/model/user.model';
import { AuthService } from 'src/app/services/auth.service';
import { LangsRepository } from 'src/app/services/langs.repository';
import { UsersRepository } from 'src/app/services/users.repository';

@Component({
    selector: 'settings-notifications-overlay',
    templateUrl: 'settings.overlay.notifications.html',
    styleUrls: ["../../../app.component.scss", "settings.overlay.notifications.scss"],
})
export class SettingsNotificationsOverlayComponent {
    public ready: boolean = false;

    @Input() active: boolean;
    @Output() activeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() currentLang: ILang;
    @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
    @Input() user: User;

    public langsReady = false;
    public usersReady = false;

    constructor(
        private langsRepository: LangsRepository,
        private usersRepository: UsersRepository,
        private authService: AuthService
    ) {
        this.ready = true;
        this.init();

    }

    public close(): void {
        this.active = false;
        this.activeChange.emit(false);
    }

    public init() {
        this.langsRepository.ready.subscribe(r => { if (r) { this.langsReady = true; } });
    }

    public save(): void {
        this.usersRepository.sendUpdateUserSettings(this.user).subscribe(res => {
            this.authService.updateUserSettings(this.user);
            this.active = false;
            this.activeChange.emit(false);
        });
    }



}
