import { Component, Input, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Lead } from '../../../model/lead.model';
import { Platform } from '@ionic/angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { AppService } from '../../../services/app.service';
import { ILang } from '../../../model/lang.interface';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
    selector: 'lead-footer',
    templateUrl: 'lead.footer.component.html',
    styleUrls: ["../../../app.component.scss", "./lead.footer.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class LeadFooterComponent {
    @Input() lead: Lead;
    @Input() currentLang: ILang;
    public playerActive: boolean = false;
    @ViewChild('player', { static: false }) private player: ElementRef;

    constructor(
        private platform: Platform,
        private callNumber: CallNumber,
        private appService: AppService,
    ) {
    }

    get isCordova(): boolean { return this.platform.is("cordova"); }
    get isMobile(): boolean { return this.platform.is("android") || this.platform.is("ios"); }

    get phoneForLead(): string {
        if (this.lead) {
            var phone = this.lead.phone;
            return "972" + phone.substring(1);
        }
        return "";
    }

    public openPlayer(): void {
        if (this.lead.callRecordingUrl) {
            this.playerActive = true;
            (this.player.nativeElement as HTMLAudioElement).play();
        }
    }

    public closePlayer(): void {
        (this.player.nativeElement as HTMLAudioElement).pause();
        this.playerActive = false;
    }

    public call(): void {
        this.callNumber.callNumber(this.lead.phone, false).catch(err => {
            this.appService.showError("Cordova error", "Call failed");
        });
    }

    public openWhatsApp() {
        var uri = encodeURI('https://wa.me/' + this.phoneForLead);

        if (this.platform.is("android")) {
            var obj = new InAppBrowser().create("whatsapp://send?phone=" + this.phoneForLead, '_system');
            obj.show();
        } else if (this.platform.is("ios")) {
            var obj = new InAppBrowser().create(uri, '_system');
            obj.show();
        } else {
            window.location.href = uri;
        }
    }
}
